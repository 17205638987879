export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/beneficios": [3],
		"/datos-de-contacto-publico": [4],
		"/faqs": [5],
		"/inicio-sesion": [6],
		"/recuperar-contrasena": [7],
		"/registro": [8],
		"/terminos-y-condiciones": [9],
		"/usuario": [10],
		"/usuario/asesorias": [11],
		"/usuario/beneficios": [12],
		"/usuario/eventos": [13],
		"/usuario/faqs": [14],
		"/usuario/importaciones": [15],
		"/usuario/inversiones": [16],
		"/usuario/manejo-de-suscripcion": [~17],
		"/usuario/mi-perfil": [~18],
		"/usuario/planes-de-pago": [19],
		"/usuario/sorteo-ruleta": [20],
		"/usuario/tgs": [21],
		"/usuario/tienda": [22],
		"/usuario/tienda/[productoId]": [~23]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';